import revive_payload_client_tXIkkFq409 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.11.0_jiti@1.21.6__ioredis@5.4.1_gfnjw57dkrhvepqvvd2762k4rm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KxLkmH2AfJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.11.0_jiti@1.21.6__ioredis@5.4.1_gfnjw57dkrhvepqvvd2762k4rm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zEJ0HhRwLB from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.11.0_jiti@1.21.6__ioredis@5.4.1_gfnjw57dkrhvepqvvd2762k4rm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_43SylJ5zzo from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.11.0_jiti@1.21.6__ioredis@5.4.1_gfnjw57dkrhvepqvvd2762k4rm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_67ZfDfTo2S from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.11.0_jiti@1.21.6__ioredis@5.4.1_gfnjw57dkrhvepqvvd2762k4rm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wDMPTvKgew from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.11.0_jiti@1.21.6__ioredis@5.4.1_gfnjw57dkrhvepqvvd2762k4rm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Am6zIg3NwW from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vue@3.5.7_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_oAld4jI7so from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.11.0_jiti@1.21.6__ioredis@5.4.1_gfnjw57dkrhvepqvvd2762k4rm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_dlry8Kmpr6 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_ZsJS6LDbhT from "/vercel/path0/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.7_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.25.2_reac_7rv3etv3yyszjeklnik3bt5eum/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_Orjmv2HCc5 from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import vuetify_icons_UcBCbKstog from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.7.3_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vite@5.4.7_@types+nod_zh7novjhqm3cukveol5twi33nm/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import vuetify_no_client_hints_QjgZKwHPC9 from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.7.3_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vite@5.4.7_@types+nod_zh7novjhqm3cukveol5twi33nm/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import templates_pwa_client_2fa56a5f_QNnsuYbOie from "/vercel/path0/.nuxt/templates.pwa.client.2fa56a5f.ts";
import hotjar_pBWYs5JQbk from "/vercel/path0/plugins/hotjar.ts";
import vue_datepicker_dyozMmv23j from "/vercel/path0/plugins/vue-datepicker.ts";
import vue_draggable_OKRqLUiGtd from "/vercel/path0/plugins/vue-draggable.ts";
import vuetify_uQEpjndacp from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.7.3_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vite@5.4.7_@types+nod_zh7novjhqm3cukveol5twi33nm/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify.mjs";
export default [
  revive_payload_client_tXIkkFq409,
  unhead_KxLkmH2AfJ,
  router_zEJ0HhRwLB,
  navigation_repaint_client_43SylJ5zzo,
  check_outdated_build_client_67ZfDfTo2S,
  chunk_reload_client_wDMPTvKgew,
  plugin_vue3_Am6zIg3NwW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_oAld4jI7so,
  plugin_client_dlry8Kmpr6,
  plugin_ZsJS6LDbhT,
  plugin_Orjmv2HCc5,
  vuetify_icons_UcBCbKstog,
  vuetify_no_client_hints_QjgZKwHPC9,
  templates_pwa_client_2fa56a5f_QNnsuYbOie,
  hotjar_pBWYs5JQbk,
  vue_datepicker_dyozMmv23j,
  vue_draggable_OKRqLUiGtd,
  vuetify_uQEpjndacp
]